import axiosInstance from "@/axios";

export default {
    startJob(jobData) {
        console.debug("JobsFactory startJob");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/job/standard", jobData)
                .then(response => {
                    console.debug("startJob response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("startJob error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    submitCyberlockerJob(jobData) {
        console.debug("JobsFactory submitCyberlockerJob");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/job/cyberlockersources", jobData)
                .then(response => {
                    console.debug("submitCyberlockerJob response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("submitCyberlockerJob error: ", error);
                    reject(error); // Reject with the error
                })
        });
    }
}
